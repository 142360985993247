import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Tooltip from "@mui/material/Tooltip";
import { Autocomplete, Checkbox, Grid, Modal, TextField, useMediaQuery } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { showComponent } from "../helper/helpers";
import "../helper/helper.css";
import ExcelDownload from "../../utils/components/excelDownload";
import PrintIcon from "@mui/icons-material/Print";
import { invoicenListGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import ListWrap from "../commonComponent/listWrap";
import {
  Email as EmailIcon,
} from "@mui/icons-material";
import RenderEmailModal from "../../utils/components/renderEmailModal";

const _clientId = localStorage.getItem("id");
const userType = localStorage.getItem("type");
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "clientName", label: "Client", minWidth: 40 },
  { id: "address", label: "Address", minWidth: 40 },
  { id: "invoiceNumber", label: "Invoice Number", minWidth: 40 },
  {
    id: "invoiceDate",
    label: "Invoice Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "poNumber",
    label: "PO Number",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },

  {
    id: "createdBy",
    label: "Created By",
    align: "left",
    minWidth: 40,
  },
];

function createData(
  id,
  clientName,
  address,
  invoiceNumber,
  invoiceDate,
  clientId,
  poNumber,
  createdBy
) {
  return {
    id,
    clientName,
    address,
    invoiceNumber,
    invoiceDate,
    clientId,
    poNumber,
    createdBy,
  };
}
const notNeededColumn = ["id", "clientId", "createdBy"];

export default function InvoiceList(props) {
  const invoicenListGuides = invoicenListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [invoiceList, setInvoiceList] = React.useState([]);
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Invoice Number");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [totalRows, setTotalRows] = React.useState(0);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [open, setOpen] = React.useState(false);
  const [selectedColumn, SetSelectedColumn] = React.useState([]);

  const [selected, setSelected] = React.useState([]);
  const handleSelectRow = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  const isSelected = (id) => selected.includes(id);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    const idString = (Array.isArray(id) ? id : [id]).join(',');
  
    let invoiceQuery = {
      query: `DELETE FROM customInvoice WHERE id IN (${idString});`
    };
    
    let productdetailsQuery = {
      query: `DELETE FROM productDetails WHERE type = 2 AND referenceId IN (${idString});`
    };
    
    let paymentMasterQuery = { query: `DELETE FROM paymentMaster WHERE invoiceNumber IN (SELECT invoiceNumber FROM customInvoice WHERE id IN (${idString}));` };

  
    props.setLoader(true);
  
    axiosWithToken
      .post(BASE_URL + `dynamic`, paymentMasterQuery)
      .then(() => {
        return axiosWithToken.post(BASE_URL + `dynamic`, invoiceQuery);
      })
      .then(() => {
        return axiosWithToken.post(BASE_URL + `dynamic`, productdetailsQuery);
      })
      .then((res) => {
        toast.success("Invoice deleted successfully");
        props.setLoader(false);
      })
      .catch((error) => {
        console.log("err", error);
        props.setLoader(false);
      });
  };
  

  //customInvoice

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        Client: "client.companyName",
        "PO Number": "invoice.poNumber",
        "Invoice Number": "invoice.invoiceNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` invoice.invoiceDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }
    if (userType != "1")
      whereConditions +=
        whereConditions.length > 0
          ? `and invoice.createdBy=${_clientId}`
          : `where invoice.createdBy=${_clientId}`;
    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `SELECT client.companyName, invoice.clientId, invoice.poNumber, invoice.invoiceNumber, invoice.invoiceDate, invoice.poDate, invoice.createdBy, invoice.id, a.address AS address FROM customInvoice AS invoice JOIN clients AS client ON invoice.clientId = client.id LEFT JOIN addresses AS a ON a.id = SUBSTRING_INDEX(SUBSTRING_INDEX(invoice.address, ',', 1), ',', -1) ${whereConditions} ORDER BY invoice.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(invoice.id) as no_of_rows FROM customInvoice AS invoice JOIN clients AS client ON invoice.clientId = client.id LEFT JOIN addresses AS a ON a.id = SUBSTRING_INDEX(SUBSTRING_INDEX(invoice.address, ',', 1), ',', -1) ${whereConditions} ORDER BY invoice.id DESC`;
    return data;
  };

  function getAllInvoice() {
    const data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setInvoiceList(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getAllInvoice", "Invoice List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
        // props.setLoader(false);
      });
  }

  function getTotalRows() {
    const data = getQuery("count");
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getTotalRows", "Invoice List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  const initiatInvoiceList = () => {
    var rowData = [];

    for (let i = 0; i < invoiceList.length; i++) {
      rowData.push(
        createData(
          invoiceList[i].id,
          invoiceList[i].companyName,
          invoiceList[i].address,
          invoiceList[i].invoiceNumber,
          invoiceList[i].invoiceDate
            ? moment(invoiceList[i].invoiceDate).format("DD-MM-YYYY")
            : "",
          invoiceList[i].clientId,
          invoiceList[i].poNumber,

          invoiceList[i].createdBy
        )
      );
    }

    setInvoiceData(rowData);
  };

  // api calls
  function getUsers() {
    const data = {
      query: "SELECT id,userName FROM users",
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getUsers", "Invoice List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }


 const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  var refresh = () => {
    window.location.reload(false);
  };

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getTotalRows();
    getAllInvoice();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Invoice Number");
    setSearchKey("");
    setFrom(null);
    setTo(null);
    setSearched(false); 
  };

  useEffect(() => {
    initiatInvoiceList();
  }, [invoiceList]);

  useEffect(() => {
    getAllInvoice();
    getTotalRows();  
  }, [rowsPerPage, page]);

  useEffect(() => {
    if(searched==false){
    getAllInvoice();
    getTotalRows();
  }
  if (searched) {
    setPage(0);
  }
  }, [searched]);

  useEffect(() => {
      getUsers();
  }, []);

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ padding: "5px 0px",}}
    >
      <Grid item xs={6} sm={3} md={1} lg={1.5} i id="invoicelist_datefrom">
      <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
            className="Listinputcss"
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setFrom(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={3} md={1} lg={1.5} id="invoicelist_dateto">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="To"
              className="Listinputcss"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setTo(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2} >
      <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          className="Listinputcss"
          size="small"
          id="invoicelist_searchby"
          value={searchBy}
          options={[
            { key: "companyName", label: "Client" },
            { key: "poNumber", label: "PO Number" },
            { key: "invoiceNumber", label: "Invoice Number" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
      <TextField
          id="invoicelist_searchbytext"
          label={"enter " + searchBy}
          size="small"
          inputProps={{
            autoComplete: 'off',
          }}
          className="Listinputcss"
          variant="outlined"
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
          sx={{ marginLeft: "10px" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} textAlign={"left"}>
      <Button
          id="invoicelist_searchbtn"
          variant="contained"
          size="small"
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}	
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2} textAlign={"left"}>
      <ExcelDownload
          finalData={invoiceData}
          notNeededColumn={notNeededColumn}
        />
      </Grid>
    </Grid>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  }; 

  return (
    <ListWrap>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          {showComponent("add") && (
            <Button
              id="invoicelist_createbtn"
              variant="contained"
              size="small"
              component={Link}
              to="/Invoice/createInvoice"
            >
              CREATE INVOICE
            </Button>
          )}
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
              <Button
                style={{ display: isMobile ? "block" : "none" }}
                onClick={handleFilterOpen}
              >
                <FilterAltIcon />
              </Button>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              invoicenListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
        </Box>
      </div>
      {!isMobile && filterSection()}
      {selected.length > 0 && (
        <Box sx={{textAlign:"left", margin:"10px 0px"}}>
          <Button
          variant="contained"
          size="small"
          color={"error"}
          onClick={() => {
            if (window.confirm("Really want to delete invoice?")){
              submitSiteDelete(selected);
            } 
          }}
          >Multiple Delete</Button>
        </Box>
      )}
    <TableContainer>
      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="invoicelist_table"
        sx={{ borderCollapse: "collapse" }}
      >
        <TableHead>
          <TableRow>
          {(editAccess?.includes(2) || editAccess?.includes(0)) && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selected.length > 0 && selected.length < invoiceData?.length}
                checked={selected.length === invoiceData?.length}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSelected(invoiceData?.map((row) => row.id));
                  } else {
                    setSelected([]);
                  }
                }}
              />
            </TableCell>
            )}
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceData?.length > 0 ? (
            invoiceData
            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {(editAccess?.includes(2) || editAccess?.includes(0)) && (
                   <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected(row.id)}
                      onChange={() => handleSelectRow(row.id)}  
                    />
                  </TableCell>
                   )}
                  {columns.map((column) => {
                    // let value = row[column.id];
                    let value;
                    if (column.id === "createdBy") {
                      let userObject = users.filter(
                        (e) => e.id === row[column.id]
                      );
                      value = row[column.id] ? userObject[0]?.userName : null;
                    } else {
                      value = row[column.id];
                    }
                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup
                      size="small"
                      aria-label="small button group"
                      className="action-container"
                    >
                      {showComponent("show") && (
                        <Tooltip title="Edit Invoice" placement="top-start">
                          <Button
                            id="invoicelist_edit"
                            component={Link}
                            to={`/Invoice/editInvoice/${row.clientId}/${row.poNumber}/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                      )}

                      <Tooltip title="Print Invoice" placement="top-start">
                        <Button
                          id="invoicelist_download"
                          component={Link}
                          to={`/Invoice/viewInvoice/${row.clientId}/${row.id}`}
                        >
                          <PrintIcon />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Email" placement="top-start">
                        <Button
                          id="invoicelist_Email"
                          // component={Link}
                          // to={`/Invoice/viewInvoice/${row.clientId}/${row.id}`}
                          onClick={()=>{
                            SetSelectedColumn(row); 
                            handleOpen();
                          }}
                        >
                          <EmailIcon />
                        </Button>
                      </Tooltip>


                      {showComponent("delete") && (
                        <Tooltip title="Delete Invoice" placement="top-start">
                          <Button
                            id="invoicelist_delete"
                            disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                            onClick={(e) => {
                              if (
                                window.confirm("Really want to delete Invoice?")
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon  style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                          </Button>
                        </Tooltip>
                      )}


                      
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
               <h6><b>Data Not Found</b></h6>
              </TableCell>
            </TableRow>
          )} 
        </TableBody>

        <RenderEmailModal
        open={open}
        handleClose={handleClose}
        selectedColumn={selectedColumn}
        refresh={refresh}
        formType={"invoice"}
      />
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 30, 50]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </TableContainer>
    </ListWrap>
  );
}
