var metric,
  imperial;

metric = {
  'mm³/s': {
    name: {
      singular: 'Cubic Millimeter per second',
      plural: 'Cubic Millimeters per second',
    },
    to_anchor: 1 / 1000000,
  },
  'cm³/s': {
    name: {
      singular: 'Cubic Centimeter per second',
      plural: 'Cubic Centimeters per second',
    },
    to_anchor: 1 / 1000,
  },
  'ml/s': {
    name: {
      singular: 'Millilitre per second',
      plural: 'Millilitres per second',
    },
    to_anchor: 1 / 1000,
  },
  'cl/s': {
    name: {
      singular: 'Centilitre per second',
      plural: 'Centilitres per second',
    },
    to_anchor: 1 / 100,
  },
  'dl/s': {
    name: {
      singular: 'Decilitre per second',
      plural: 'Decilitres per second',
    },
    to_anchor: 1 / 10,
  },
  'l/s': {
    name: {
      singular: 'Litre per second',
      plural: 'Litres per second',
    },
    to_anchor: 1,
  },
  'l/min': {
    name: {
      singular: 'Litre per minute',
      plural: 'Litres per minute',
    },
    to_anchor: 1 / 60,
  },
  'l/h': {
    name: {
      singular: 'Litre per hour',
      plural: 'Litres per hour',
    },
    to_anchor: 1 / 3600,
  },
  'kl/s': {
    name: {
      singular: 'Kilolitre per second',
      plural: 'Kilolitres per second',
    },
    to_anchor: 1000,
  },
  'kl/min': {
    name: {
      singular: 'Kilolitre per minute',
      plural: 'Kilolitres per minute',
    },
    to_anchor: 50 / 3,
  },
  'kl/h': {
    name: {
      singular: 'Kilolitre per hour',
      plural: 'Kilolitres per hour',
    },
    to_anchor: 5 / 18,
  },
  'm³/s': {
    name: {
      singular: 'Cubic Meter per second',
      plural: 'Cubic Meters per second',
    },
    to_anchor: 1000,
  },
  'm³/min': {
    name: {
      singular: 'Cubic Meter per minute',
      plural: 'Cubic Meters per minute',
    },
    to_anchor: 50 / 3,
  },
  'm³/h': {
    name: {
      singular: 'Cubic Meter per hour',
      plural: 'Cubic Meters per hour',
    },
    to_anchor: 5 / 18,
  },
  'km³/s': {
    name: {
      singular: 'Cubic Kilometer per second',
      plural: 'Cubic Kilometers per second',
    },
    to_anchor: 1000000000000,
  },
};

imperial = {
  'tsp/s': {
    name: {
      singular: 'Teaspoon per second',
      plural: 'Teaspoons per second',
    },
    to_anchor: 1 / 6,
  },
  'Tbs/s': {
    name: {
      singular: 'Tablespoon per second',
      plural: 'Tablespoons per second',
    },
    to_anchor: 1 / 2,
  },
  'in³/s': {
    name: {
      singular: 'Cubic Inch per second',
      plural: 'Cubic Inches per second',
    },
    to_anchor: 0.55411,
  },
  'in³/min': {
    name: {
      singular: 'Cubic Inch per minute',
      plural: 'Cubic Inches per minute',
    },
    to_anchor: 0.55411 / 60,
  },
  'in³/h': {
    name: {
      singular: 'Cubic Inch per hour',
      plural: 'Cubic Inches per hour',
    },
    to_anchor: 0.55411 / 3600,
  },
  'fl-oz/s': {
    name: {
      singular: 'Fluid Ounce per second',
      plural: 'Fluid Ounces per second',
    },
    to_anchor: 1,
  },
  'fl-oz/min': {
    name: {
      singular: 'Fluid Ounce per minute',
      plural: 'Fluid Ounces per minute',
    },
    to_anchor: 1 / 60,
  },
  'fl-oz/h': {
    name: {
      singular: 'Fluid Ounce per hour',
      plural: 'Fluid Ounces per hour',
    },
    to_anchor: 1 / 3600,
  },
  'cup/s': {
    name: {
      singular: 'Cup per second',
      plural: 'Cups per second',
    },
    to_anchor: 8,
  },
  'pnt/s': {
    name: {
      singular: 'Pint per second',
      plural: 'Pints per second',
    },
    to_anchor: 16,
  },
  'pnt/min': {
    name: {
      singular: 'Pint per minute',
      plural: 'Pints per minute',
    },
    to_anchor: 4 / 15,
  },
  'pnt/h': {
    name: {
      singular: 'Pint per hour',
      plural: 'Pints per hour',
    },
    to_anchor: 1 / 225,
  },
  'qt/s': {
    name: {
      singular: 'Quart per second',
      plural: 'Quarts per second',
    },
    to_anchor: 32,
  },
  'gal/s': {
    name: {
      singular: 'Gallon per second',
      plural: 'Gallons per second',
    },
    to_anchor: 128,
  },
  'gal/min': {
    name: {
      singular: 'Gallon per minute',
      plural: 'Gallons per minute',
    },
    to_anchor: 32 / 15,
  },
  'gal/h': {
    name: {
      singular: 'Gallon per hour',
      plural: 'Gallons per hour',
    },
    to_anchor: 8 / 225,
  },
  'ft³/s': {
    name: {
      singular: 'Cubic Foot per second',
      plural: 'Cubic Feet per second',
    },
    to_anchor: 957.506,
  },
  'ft³/min': {
    name: {
      singular: 'Cubic Foot per minute',
      plural: 'Cubic Feet per minute',
    },
    to_anchor: 957.506 / 60,
  },
  'ft³/h': {
    name: {
      singular: 'Cubic Foot per hour',
      plural: 'Cubic Feet per hour',
    },
    to_anchor: 957.506 / 3600,
  },
  'yd³/s': {
    name: {
      singular: 'Cubic Yard per second',
      plural: 'Cubic Yards per second',
    },
    to_anchor: 25852.7,
  },
  'yd³/min': {
    name: {
      singular: 'Cubic Yard per minute',
      plural: 'Cubic Yards per minute',
    },
    to_anchor: 25852.7 / 60,
  },
  'yd³/h': {
    name: {
      singular: 'Cubic Yard per hour',
      plural: 'Cubic Yards per hour',
    },
    to_anchor: 25852.7 / 3600,
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: 'l/s',
      ratio: 33.8140227,
    },
    imperial: {
      unit: 'fl-oz/s',
      ratio: 1 / 33.8140227,
    },
  },
};
