import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Grid, TextField, Autocomplete, Checkbox } from "@mui/material";
import { BASE_URL } from "./../../global";
import ExcelDownload from "../../utils/components/excelDownload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ListWrap from "../commonComponent/listWrap";

let notNeededColumn = ["id"];
export default function ProgressReport() {
  const [page, setPage] = React.useState(0);
  const [srfNo, setSrfNo] = React.useState(0);
  const [searchSRNO, setsearchSRNO] = React.useState([]);
  const [data, setData] = React.useState("");
  const [selectedSRFNo, setSelectedSRFNo] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const printComponentRef = React.useRef();
  const [searched, setSearched] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getSRFNOList = (event) => {
    let url = BASE_URL;
    let endPoint = "";
    endPoint = `srfs?_where=(status,eq,1)&_sort=-id`;
    axiosWithToken
      .get(url + endPoint)
      .then((res) => {
        setSrfNo(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var pushArray = [];

  for (let i = 0; i < srfNo.length; i++) {
    pushArray.push({
      id: srfNo[i].id,
      label: srfNo[i].serviceReqNumber,
    });
  }

  const getData = (event) => {
    let url = BASE_URL;
    const query = {
      query: `SELECT companyName, serviceReqNumber AS serviceReqNumber, COUNT(*) AS Total_Instrument, COUNT(CASE WHEN calibratedby IS NOT NULL THEN 1 END) AS Calibrated, COUNT(CASE WHEN approvedby IS NOT NULL THEN 1 END) AS Approved, COUNT(CASE WHEN calibratedby IS NULL THEN 1 END) AS Pending FROM certificatesView WHERE serviceReqNumber IN (${searchSRNO
        .map((srno) => `'${srno}'`)
        .join(",")}) GROUP BY companyName, serviceReqNumber;`,
    };

    axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const search = () => {
    setSearched(true);
    getData();
  };
  const clearSearch = () => {
    setSearched(false);
    setSelectedSRFNo([]);
    setData([]);
  };

  useEffect(() => {
    getSRFNOList();
  }, []);

  return (
    <ListWrap>
      <Grid
        container
        spacing={1}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} textAlign={"left"}>
          <Autocomplete
            size="small"
            className="Listinputcss"
            id="combo-box-demo"
            options={pushArray}
            disableCloseOnSelect
            multiple
            limitTags={3}
            value={pushArray.filter((option) =>
              selectedSRFNo.includes(option.id)
            )}
            onChange={(event, value) => {
              const selectedIds = value.map((e) => e.id);
              const selectedLabels = value.map((e) => e.label);

              console.log(selectedIds);
              setSelectedSRFNo(selectedIds);
              setsearchSRNO(selectedLabels);
              setSearched(false);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={selectedSRFNo.includes(option.id)}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Select SRF No" />}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            className="Listinputcss"
            color={searched ? "error" : "primary"}
            sx={{ m: 0 }}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
        </Grid>
        {data.length > 0 && (
          <>
            <Grid item xs={4} sm={3} md={3} lg={2} textAlign={"left"}>
              <ExcelDownload
                finalData={data}
                notNeededColumn={notNeededColumn}
              />
            </Grid>
          </>
        )}
      </Grid>

      <TableContainer>
        <div style={{ width: "100%", margin: "auto" }} ref={printComponentRef}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            style={{ borderCollapse: "collapse", marginTop: "5px" }}
          >
            <TableBody className="single-border-table">
              <TableRow>
                <TableCell>
                  <b>Sr.No.</b>
                </TableCell>
                <TableCell>
                  <b>Client</b>
                </TableCell>
                <TableCell>
                  <b>SRF No.</b>
                </TableCell>
                <TableCell>
                  <b>Total Instrument</b>
                </TableCell>
                <TableCell>
                  <b>Calibrated</b>
                </TableCell>
                <TableCell>
                  <b>Approved</b>
                </TableCell>
                <TableCell>
                  <b>Pending</b>
                </TableCell>
              </TableRow>
              {data?.length > 0 ? (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        id="pagbreaksborder"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        style={{ pageBreakInside: "avoid" }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.companyName}</TableCell>
                        <TableCell>{row.serviceReqNumber}</TableCell>
                        <TableCell>{row.Total_Instrument}</TableCell>
                        <TableCell>{row.Calibrated}</TableCell>
                        <TableCell>{row.Approved}</TableCell>
                        <TableCell>{row.Pending}</TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <h6>
                      <b>Data Not Found</b>
                    </h6>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 30, 100, { value: data.length, label: "All" }]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </ListWrap>
  );
}
