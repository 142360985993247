import autoTable from "jspdf-autotable";
import { customFontFamily, tableConfiguration } from "./srfsConfig";
import { layoutConfig, printSRFSConfiguration } from "./SRFSConstant";

export const drawText = (
  text,
  xSize,
  ySize,
  align = "left",
  fontSize = 10,
  topSpace = 0,
  bottomSpace = 0,
  fontType
) => {
  printSRFSConfiguration.pdfDoc.setFontSize(fontSize);
  if (fontType == "normalfont") {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  } else {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  }

  let yPos = ySize + topSpace;

  // Align the text horizontally
  let textWidth = printSRFSConfiguration.pdfDoc.getTextWidth(text);
  let xPos = xSize;

  if (align === "left") {
    xPos += 1;
  } else if (align === "center") {
    xPos =
      (printSRFSConfiguration.pdfDoc.internal.pageSize.width - textWidth) / 2;
  } else if (align === "right") {
    xPos =
      printSRFSConfiguration.pdfDoc.internal.pageSize.width - textWidth - xSize;
  }

  printSRFSConfiguration.pdfDoc.text(text, xPos, yPos);
  layoutConfig.yPositionGlobal = yPos + fontSize + bottomSpace;

  if (fontType == "normalfont") {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  } else {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  }
};

export const drawBox = async (panels) => {
  let tableRows = [];
  for (let i = 0; i < panels.leftPanel.keys.length; i++) {
    tableRows.push([
      {
        content: panels.leftPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.leftPanel.values[i], styles: { halign: "left" } },
      {
        content: panels.rightPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.rightPanel.values[i], styles: { halign: "left" } },
    ]);
  }

  // Calculate the total available width of the table
  const totalPageWidth =
    printSRFSConfiguration.pageWidth - tableConfiguration?.margins.right * 2;
  const columnPercentages = [25, 25, 25, 25];
  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize:
        panels.tableTextfontSize || printSRFSConfiguration.fontSizes.hten,
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printSRFSConfiguration.cellPaddingTable,
      halign: "middle",
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const drawfirst2ColumnRowspan = async (panels) => {
  let tableRows = [];
  const rowSpan = panels?.rightPanel?.keys?.length;

  let row = [
    {
      content: panels?.leftPanel.keys[0],
      rowSpan: rowSpan,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    },
    {
      content: panels?.leftPanel.values[0],
      rowSpan: rowSpan,
      styles: { valign: "middle", halign: "left" },
    },
    {
      content: panels?.rightPanel.keys[0],
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    },
    {
      content: panels?.rightPanel.values[0],
      styles: { valign: "middle", halign: "left" },
    },
  ];
  tableRows.push(row);

  for (let i = 1; i < panels?.rightPanel.keys.length; i++) {
    let row = [
      {
        content: panels?.rightPanel.keys[i],
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
          halign: "left",
        },
      },
      {
        content: panels?.rightPanel.values[i],
        styles: { valign: "middle", halign: "left" },
      },
    ];
    tableRows.push(row);
  }

  // Add the final row with Address (colSpan 3)
  let addressRow = [
    {
      content: panels?.leftPanel.keys[1], // "Client" key, to keep the layout
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    },
    {
      content: panels?.leftPanel.values[1], // Address content
      colSpan: 3, // This will span across the last 3 columns
      styles: { valign: "middle", halign: "left" },
    },
  ];

  tableRows.push(addressRow);

  // Calculate the total available width of the table
  const totalPageWidth =
    printSRFSConfiguration.pageWidth - tableConfiguration?.margins.right * 2;
  const columnPercentages = [20, 35, 20, 25];

  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  // Render the table using autoTable
  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: printSRFSConfiguration.fontSizes.hten,
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printSRFSConfiguration.cellPaddingTable,
      halign: "middle",
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 0;
};
