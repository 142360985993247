import React, { useEffect } from "react";
import { BASE_URL } from "./../../global";
import moment from "moment";
import HelpIcon from "@mui/icons-material/Help";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Tables from "../../utils/components/tables";
import { ExpandMore } from "@mui/icons-material";
import { notifiy } from "../notification/Notification";
import { requestFormListGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ListWrap from "../commonComponent/listWrap";
// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
// } from "@material-ui/core";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "Id",
    label: "Id",
    align: "left",
    minWidth: 40,
  },
  {
    id: "userName",
    label: "Requested User",
    align: "left",
    minWidth: 40,
  },

  {
    id: "companyName",
    label: "Client",
    align: "left",
    minWidth: 40,
  },
  {
    id: "requestType",
    label: "Request Type",
    align: "left",
    minWidth: 40,
  },
  {
    id: "message",
    label: "Request Message",
    align: "left",
    minWidth: 40,
  },
];

const searchKeys = {
  "Client Name": "userName",
  "Request Type": "requestType",
  Client: "companyName",
};

const RequestArray = [
  { id: 1, label: "Renewal" },
  { id: 2, label: "Part Replacement" },
  { id: 3, label: "Repairing" },
  { id: 3, label: "Maintaince  " },
];

const RequestMap = (() => {
  let tmp = {};
  RequestArray.map((e) => (tmp[e.id] = e));
  RequestArray.map((e) => (tmp[e.label] = e));
  return tmp;
})();

const RequestFormList = () => {
  const requestFormListGuides = requestFormListGuide();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [searchBy, setSearchBy] = React.useState("Client Name");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userId = localStorage.getItem("id");
  const _branch = localStorage.getItem("branch");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({
    requestType: undefined,
    message: "",
  });
  const [searched, setSearched] = React.useState(false);

  const getQuery = (count) => {
    let whereConditions = `where rF.status != -1 ${
      _userType == 3 ? ` and rF.requester = ${_userId}` : ""
    }`;
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      whereConditions += `and ${searchKeys[searchBy]} like '%${searchKey}%'`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let conditions = `FROM requestForm AS rF JOIN users As u ON rF.requester = u.id LEFT JOIN clients as c ON u.clientId = c.id ${whereConditions} order by u.id DESC ${pagination_settings}`;

    let data = {
      query: `SELECT rF.*, u.userName AS userName, c.companyName AS companyName ${conditions}`,
    };

    if (count) data.query = `SELECT COUNT(*) AS no_of_rows ${conditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    getTotalRows();
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        res.data.map((d, i) => {
          res.data[i].Id = res.data[i].id;
          res.data[i].requestType = RequestMap[res.data[i].requestType].label;
        });
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const search = () => {
    //Search with request type is not supported yet, need to work in future
    setSearched(true);
    fetchFinalData();
  };
  const clearSearch = () => {
    setSearchBy("Client Name");
    setSearchKey("");
    setSearched(false); 
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `requestForm/${id}`)
      .then((res) => {
        toast("ducMrequestFormaster is deleted!");
        setTimeout(refresh, 250);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const handleSubmit = () => {
    let data = {
      query: `select u.id as id,u.type from users as u left join userClientAssign as uCA on u.id = uCA.userId where u.status = 1 and (u.type = 1 or u.type = 4 ${
        _clientId && _clientId !== "null"
          ? `or (u.type = 2 and uCA.companyId = ${_clientId})`
          : ""
      })`,
    };
    axiosWithToken.post(BASE_URL + `dynamic`, data).then((res) => {
      axiosWithToken
        .post(BASE_URL + "requestForm", {
          message: formData.message,
          requestType: formData.requestType.id,
          requester: _userId,
        })
        .then((res2) => {
          notifiy(
            res.data.map((d) => ({
              _from: _userId,
              _to: d.id,
              body: `${
                RequestArray[
                  Object.keys(RequestArray).filter(
                    (e) => e == formData.requestType
                  )[0]
                ]?.label || "unknown"
              }(id:${res2.data.insertId}) : ${formData.message}`,
              type: 1,
            }))
          )
            .then(() => {
              toast.success("Notified to envolved parties successfully!");
              refresh();
            })
            .catch((err) => {
              if (err.message !== "request_aborted") {
                toast.error("Something Went Wrong with notification : ", err)
              }
            }
            );
          toast.success("Request created successfully!");
        })
        .catch((err) => {
          if (err.message !== "request_aborted") {
            toast.error("Something Went Wrong request : ", err);
          }
        });
    });
  };

  var refresh = (delay) => {
    setTimeout(() => window.location.reload(false), delay || 500);
  };

  useEffect(() => {
    fetchFinalData();
  }, []);

  useEffect(() => {
    if(searched==false){
      fetchFinalData();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const actions = [
    // {
    //   id: "edit",
    //   tooltip: "Edit DUC ",
    //   link: true,
    //   linkTo: (id) => `/editRequestForm/${id}`,
    //   icon: <PreviewIcon />,
    // },
    {
      id: "delete",
      tooltip: "Delete DUC ",
      icon: <DeleteIcon style={{ color: "#dc3545" }} />,
      handler: (id) => handleDelete(id),
    },
  ];

  return (
    <ListWrap>
      <div style={{ position: "fixed", top:"3rem", right: "1rem" }}>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              requestFormListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
        {_userType == 3 && (
          <div style={{ padding: "0em 1em" }}>
            <Accordion
              expanded={open}
              onChange={() => setOpen(!open)}
              sx={{ boxShadow: "1px 1px 5px #000" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ backgroundColor: "#ccc" }}
              >
                <b>Request Form</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ float: "left" }}
                  >
                    Create/Update Request Form
                  </Typography>
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={6}>
                      <Autocomplete
                        //   disabled={params.invoiceId}
                        size="small"
                        id="combo-box-demo"
                        options={RequestArray}
                        getOptionLabel={(option) => option.label}
                        value={formData.requestType}
                        renderInput={(params) => (
                          <TextField {...params} label="Request Type *" />
                        )}
                        onChange={(_, value) => {
                          setFormData({ ...formData, requestType: value });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="Message *"
                        size="small"
                        rows={5}
                        multiline
                        maxRows={10}
                        value={formData.message}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => {
                          setFormData({ ...formData, message: e.target.value });
                        }}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Toolbar style={{ padding: "0px", overflow: "auto" }}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ m: 0 }}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Request
                    </Button>
                  </Toolbar>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
        {/* <Toolbar>
          <Button
            variant="contained"
            size="small"
            component={Link}
            to="/editRequestForm"
          >
            <b>ADD NEW</b>
          </Button>
        </Toolbar> */}
        
        
            <Grid
        container
        spacing={2}
        // justifyContent="flex-end"
        alignItems="center"
        style={{ padding: "5px 0px" }}
      >
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              sx={{
                m: 0,
                width: "100%",
              }}
              size="small"
              className="Listinputcss"
              id="request_form_list_searchby"
              value={searchBy}
              options={Object.keys(searchKeys)}
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                setSearched(false);
              }}
            />
        </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <TextField
              id="request_form_list_searchbytext"
              label={"enter " + searchBy}
              inputProps={{
                autoComplete: 'off',
              }}
              className="Listinputcss"
              value={searchKey}
              size="small"
              variant="outlined"
              onChange={(e) => {
                setSearchKey(e.target.value);
                setSearched(false);
              }}              
            />
        </Grid>
          <Grid item xs={6} sm={6} md={1} lg={1} textAlign={"left"}>
            <Button
              id="request_form_list_searchbtn"
              variant="contained"
              size="small"
              color={searched ? "error" : "primary"}
              onClick={() => {
                searched ? clearSearch() : search();
              }}
            >
              {searched ? <RestartAltIcon /> : <SearchIcon />}
            </Button>
        </Grid>
      </Grid>
      <TableContainer>
        
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </ListWrap>
  );
};

export default RequestFormList;
