import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import moment from "moment";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");


const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "nameOfTheCompany",
    label: "Name Of The Company",
    align: "left",
    minWidth: 40,
  },
  {
    id: "contactPersonName",
    label: "Contact Person Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "date",
    label: "Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "phone",
    label: "Phone",
    align: "left",
    minWidth: 40,
  },
  {
    id: "purposeOfPickUp",
    label: "Purpose Of Pickup",
    align: "left",
    minWidth: 40,
  },
  {
    id: "distance",
    label: "Distance",
    align: "left",
    minWidth: 40,
  },
];

function createData(
  id,
  nameOfTheCompany,
  contactPersonName,
  date,
  phone,
  purposeOfPickUp,
  distance
) {
  return {
    id,
    nameOfTheCompany,
    contactPersonName,
    date,
    phone,
    purposeOfPickUp,
    distance,
  };
}

export default function PickupList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pickupList, setPickupList] = React.useState([]);
  const [pickupData, setPickupData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axiosWithToken
      .patch(url + `pickup/${id}`, jsonData)
      .then((res) => {
        toast("pickup was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getPickupList = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "pickup?_where=(status,eq,1")
      .then((res) => {
        setPickupList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  useEffect(() => {
    getPickupList();
  }, []);

  const getPickupListData = () => {
    var rowDaata = [];
    for (let i = 0; i < pickupList.length; i++) {
      rowDaata.push(
        createData(
          pickupList[i].id,
          pickupList[i].nameOfTheCompany,
          pickupList[i].contactPersonName,
          pickupList[i].date
            ? moment(pickupList[i].date).format("DD-MM-YYYY")
            : "",
          pickupList[i].phone,
          pickupList[i].purposeOfPickUp,
          pickupList[i].distance,
          pickupList[i].location,
          pickupList[i].email,
          pickupList[i].quantityOfInstruments
        )
      );
    }
    setPickupData(rowDaata);
  };

  useEffect(() => {
    getPickupListData();
  }, [pickupList]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/pickup/createPickup"
          onClick={() => {
            {
            }
          }}
        >
          <b>ADD NEW PICKUP</b>
        </Button>
      </Toolbar>
      <hr />

      <table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pickupData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Pickup" placement="top-start">
                        <Button
                          component={Link}
                          to={`/master/pickup/EditPickup/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Pickup" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Really want to delete Uncertinity?"
                              )
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </table>
      <TablePagination
        rowsPerPageOptions={[10, 30, 100]}
        component="div"
        count={pickupData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
