import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { Link } from "react-router-dom";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";

import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { Box, IconButton, Table } from "@mui/material";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ListWrap from "../../commonComponent/listWrap";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "date",
    label: "Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "amount",
    label: "Amount",
    align: "left",
    minWidth: 40,
  },
  {
    id: "paymentMode",
    label: "Payment Mode",
    align: "left",
    minWidth: 40,
  },
  {
    id: "nameOfPerson",
    label: "Name Of Person",
    align: "left",
    minWidth: 40,
  },
  {
    id: "reason",
    label: "Reason",
    align: "left",
    minWidth: 40,
  },
];

function createData(id, date, amount, paymentMode, nameOfPerson, reason) {
  return {
    id,
    date,
    amount,
    paymentMode,
    nameOfPerson,
    reason,
  };
}

export default function ExpenseLsit() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [expenseList, setExpenseList] = React.useState("");
  const [rowDaata, setRowDaata] = React.useState([]);

  const userId = localStorage.getItem("id");
  const userType = localStorage.getItem("type");
  const branch = localStorage.getItem("branch");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `expenses/${id}`)
      .then((res) => {
        toast("expense was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => console.log(error));
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (whereConditions == "") {
      whereConditions += ` where expenses.status != -1`;
    } else {
      whereConditions += ` and expenses.status != -1 `;
    }
    if (userType == "2") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (expenses.createdBy=${userId})`
          : `where (expenses.createdBy=${userId})`;
    }

    whereConditions +=
      userType == 4 && branch && branch.length
        ? (whereConditions ? " and " : "") + ` clients.branch in (${branch})`
        : "";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT expenses.* FROM expenses LEFT JOIN clients ON expenses.clientid = clients.id ${whereConditions}  order by expenses.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(*) as no_of_rows FROM expenses LEFT JOIN clients ON expenses.clientid = clients.id ${whereConditions}`;

    return data;
  };

  const getExpenseList = (event) => {
    let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setExpenseList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const initializeExpenseData = () => {
    var rowData = [];
    for (let i = 0; i < expenseList.length; i++) {
      rowData.push(
        createData(
          expenseList[i].id,
          moment(
            expenseList[i].expenseDate ? expenseList[i].expenseDate : ""
          ).format("DD-MM-YYYY"),
          expenseList[i].amount,
          expenseList[i].paymentMode,
          expenseList[i].nameOfPerson,
          expenseList[i].reason
        )
      );
    }
    setRowDaata(rowData);
  };

  useEffect(() => {
    getExpenseList();
  }, []);

  useEffect(() => {
    initializeExpenseData();
  }, [expenseList]);

  return (
    <ListWrap>
      <Toolbar style={{ padding: "0px" }}>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/expense/createExpense"
        >
          <b>create EXPENSE</b>
        </Button>
      </Toolbar>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowDaata
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      let value = row[column.id];

                      if (column.id == "id") {
                        value = page * rowsPerPage + index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="Payment Voucher" placement="top-start">
                          <Button
                            component={Link}
                            to={`/master/expense/paymentVoucher/${row.id}`}
                          >
                            <NewspaperIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Edit Expense" placement="top-start">
                          <Button
                            component={Link}
                            to={`/master/expense/editExpense/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete Expense" placement="top-start">
                          <Button
                            onClick={(e) => {
                              if (
                                window.confirm("Really want to delete expense?")
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 30, 50]}
          component="div"
          count={rowDaata.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      </TableContainer>
    </ListWrap>
  );
}
