import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, TextField, Autocomplete, Tooltip } from "@mui/material";
import moment from "moment";
import { BASE_URL } from "../../../global";
import { followUpReportListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import EditWrap from "../../commonComponent/editWrap";

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 10 },
  { id: "companyName", label: "Client", minWidth: 40 },
  { id: "contactPersonName", label: "Contact Person Name", minWidth: 40 },
  { id: "emailId", label: "Email-ID", minWidth: 40 },
  {
    id: "followUpDate",
    label: "Follow Up Date",
    minWidth: 40,
    align: "left",
  },
];

function createData(id, companyName, contactPersonName, followUpDate, emailId) {
  return {
    id,
    companyName,
    contactPersonName,
    followUpDate,
    emailId,
  };
}

export default function FollowUpReport() {
  const followUpReportListGuides = followUpReportListGuide();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [client, setClient] = React.useState(0);
  const [fromDate, setFromDate] = React.useState(0);
  const [toDate, setToDate] = React.useState(0);
  const [searchData, setSearchData] = React.useState("");
  const [dateData, setDateData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [companyName, setCompanyName] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getClient = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClient(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var pushArray = [];

  for (let i = 0; i < client.length; i++) {
    pushArray.push({
      label: client[i].companyName,
    });
  }

  const FilterData = () => {
    if (companyName == null) {
      var resultProductData = data.filter(
        (data) =>
          new Date(data.followUpDate) >= new Date(fromDate) &&
          new Date(data.followUpDate) <= new Date(toDate)
      );
    } else {
      var resultProductData = data.filter(
        (data) =>
          new Date(data.followUpDate) >= new Date(fromDate) &&
          new Date(data.followUpDate) <= new Date(toDate) &&
          data.companyName == companyName.label
      );
    }
    setDateData(resultProductData);
  };

  const getData = (event) => {
    let url = BASE_URL;
    let query = {
      query:
        "SELECT en.id, client.companyName, en.emailId, en.contactPersonName, en.followUpDate FROM enquiry en JOIN clients client ON en.clientId = client.id;",
    };
    axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        // setDateData(res.data)
        console.log(res.data);
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var rowData = [];

  for (let i = 0; i < dateData.length; i++) {
    rowData.push(
      createData(
        dateData[i].id,
        dateData[i].companyName,
        dateData[i].contactPersonName,
        dateData[i].followUpDate
          ? moment(dateData[i].followUpDate).format("DD-MM-YYYY")
          : "",
        dateData[i].emailId
      )
    );
  }

  useEffect(() => {
    getClient();
    getData();
  }, []);

  return (
    <EditWrap>
      <div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                followUpReportListGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>
        {/* <Typography variant="h6" component="h6" style={{ float: "left" }}>
                    Create standard
                </Typography> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Autocomplete
              className="Listinputcss"
              size="small"
              id="followUp_Reportlist_createbtn"
              options={pushArray}
              renderInput={(params) => <TextField {...params} label="Client" />}
              onChange={(event, value) => setCompanyName(value)} // prints the selected value
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={1}
            lg={1.5}
            id="followUp_Reportlist_datefrom"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className="Listinputcss"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={fromDate ? new Date(fromDate) : ""}
                onChange={(e) => {
                  // moment(new Date()).format("YYYY-MM-DD");
                  // console.log(newValue);
                  setFromDate(e);
                  console.log(fromDate);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
            md={1}
            lg={1.5}
            id="followUp_Reportlist_dateto"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className="Listinputcss"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="Due Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={toDate ? new Date(toDate) : ""}
                onChange={(newValue) => {
                  setToDate(newValue);
                  console.log(toDate);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={1}>
            <Button
              id="followUp_Reportlist_searchbtn"
              variant="contained"
              size="small"
              onClick={(e) => {
                FilterData();
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            id="followUp_Reportlist_table"
            sx={{ borderCollapse: "collapse" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 30, 100]}
            component="div"
            count={dateData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </EditWrap>
  );
}
