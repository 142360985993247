import autoTable from "jspdf-autotable";
import { generateAndAddQRCodes } from "./commonTable";
import { layoutConfig, printcertificateConfigData, printCertificateConfiguration } from "./printcertificateConstant";
import { customFontFamily, tableConfiguration } from "./certificateConfig";

export const firstsectionfooter = async () => {
  await generateAndAddQRCodes(layoutConfig.xPositionGlobal, 80, true, true);

  let tables = [];
  let tablerows = [
    {
      content: "Calibration Procedure",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
      },
    },
    {
      content: "As Received Conditions",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
      },
    },
    {
      content: "As Shipped Condition",
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
      },
    },
  ];
  tables.push(tablerows);

  tablerows = [
    printcertificateConfigData.certificateDetailsConfig?.calProcRefNo ||
      printcertificateConfigData.certificateDetailsConfig
        ?.calibrationProcedureNo ||
      "",
    printcertificateConfigData.certificateDetailsConfig?.ConOfDuc || "",
    "Cleaned & Calibrated",
  ];
  tables.push(tablerows);

  // Using autoTable to add the table
  autoTable(printCertificateConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tables,
    theme: "grid",
    headStyles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      fontSize: printCertificateConfiguration.fontSizes.h2,
      font: customFontFamily?.boldFont,
    },
    styles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      lineColor: printCertificateConfiguration.textColor,
      lineWidth: printCertificateConfiguration.lineWidth,
      fontSize: printCertificateConfiguration.fontSizes.h2,
      halign: "left",
      font: customFontFamily?.normalFont,
    },
  });

  layoutConfig.yPositionGlobal +=
    printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const drawAdditionalTable = async (setPageSection) => {
  await setPageSection();
  const tablesdata = [
    [
      {
        content: "Condition of unit under calibration on receipt",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      printcertificateConfigData.certificateDetailsConfig?.ConOfDuc || "",
    ],
    [
      {
        content: "Method used - Reva Phoenix Document Number",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      printcertificateConfigData.certificateDetailsConfig?.calProcRefNo ||
        printcertificateConfigData.certificateDetailsConfig
          ?.calibrationProcedureNo ||
        "",
    ],
    [
      {
        content: "Reference Standard (International / National)",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
        },
      },
      printcertificateConfigData.certificateDetailsConfig?.isaStandard ||
        printcertificateConfigData.certificateDetailsConfig
          ?.referenceStandards ||
        "",
    ],
  ];
  autoTable(printCertificateConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tablesdata,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printCertificateConfiguration.textColor,
      lineColor: printCertificateConfiguration.textColor,
      lineWidth: printCertificateConfiguration.lineWidth,
      fontSize: printCertificateConfiguration.fontSizes.h2,
      halign: "left",
      valign: "middle",
      font: customFontFamily?.normalFont,
    },
  });
  layoutConfig.yPositionGlobal =
    printCertificateConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};
