import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from "@mui/material/Button";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, TextField, Autocomplete, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ExcelDownload from "../../utils/components/excelDownload";
import ListWrap from "../commonComponent/listWrap";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "clientName", label: "Client", minWidth: 40 },
  {
    id: "paymentDate",
    label: "Payment Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
    minWidth: 40,
  },
  {
    id: "invoiceDate",
    label: "Invoice Date",
    minWidth: 40,
  },

  {
    id: "totalAmount",
    label: "Total Amount",
    minWidth: 40,
    align: "left",
  },
  {
    id: "paidAmount",
    label: "Paid Amount",
    minWidth: 40,
    align: "left",
  },
  {
    id: "discount",
    label: "Discount %",
    minWidth: 40,
    align: "left",
  },
  {
    id: "dueAmount",
    label: "Due Amount",
    minWidth: 40,
    align: "left",
  },
];

function createData(
  id,
  clientName,
  paymentDate,
  invoiceNumber,
  invoiceDate,
  totalAmount,
  paidAmount,
  discount,
  dueAmount
) {
  return {
    id,
    clientName,
    paymentDate,
    invoiceNumber,
    invoiceDate,
    totalAmount,
    paidAmount,
    discount,
    dueAmount,
  };
}
const notNeededColumn = ["id", "clientId", "createdBy", "status"];
export default function AccountStatement() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [paymentList, setPaymentList] = React.useState([]);
  const [paymentData, setPaymentData] = React.useState([]);
  const [client, setClient] = React.useState(0);
  const [searchBy, setSearchBy] = React.useState("Client");
  const [selectDateBy, setSelectDateBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [pushArrays, setPushArray] = React.useState([]);
  const [dateData, setDateData] = React.useState([]);
  const [companyName, setCompanyName] = React.useState(null);
  const [totalAmounts, setTotalAmounts] = React.useState(null);
  const [totalDueAmounts, setTotalDueAmounts] = React.useState(null);
  const [totalPaidAmounts, setTotalPaidAmounts] = React.useState(null);
  const [searched, setSearched] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getData = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "client.companyName",
        "Invoice Number": "payment.invoiceNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` ${selectDateBy} between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    if (whereConditions === "") {
      whereConditions = " where ";
    } else {
      whereConditions += " and ";
    }

    whereConditions += " payment.status = 1 ";

    let data = {
      query: `SELECT payment.id AS payment_id, payment.totalAmount AS payment_totalAmount, payment.address AS payment_address, payment.contactNumber AS payment_contactNumber, payment.conveyanceCharges AS payment_conveyanceCharges, payment.discount AS payment_discount, payment.gstType AS payment_gstType, payment.invoiceDate AS payment_invoiceDate, payment.invoiceNumber AS payment_invoiceNumber, payment.poNumber AS payment_poNumber, payment.subject AS payment_subject, client.companyName AS client_companyName, payment.paymentDate AS payment_paymentDate, payment.paymentMode AS payment_paymentMode, payment.paidAmount AS payment_paidAmount FROM paymentMaster AS payment JOIN clients AS client ON payment.clientId = client.id ${whereConditions} order by payment.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(payment.id) as no_of_rows FROM paymentMaster AS payment JOIN clients AS client ON payment.clientId = client.id ${whereConditions} order by payment.id DESC ${pagination_settings}`;
    return data;
  };

  const getPaymentList = (event) => {
    const data = getData();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setPaymentList(res.data);
        setDateData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // api calls
  function getTotalRows() {
    const data = getData("count");
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const search = () => {
    setSearched(true);
    getPaymentList();
  };
  const clearSearch = () => {
    setSearchBy("Client");
    setSearchKey("");
    setFrom(null);
    setTo(null);
    setSearched(false); 
  };

  const initiatInvoiceList = () => {
    var rowData = [];
    var totalAmt = 0;
    var totalDueAmt = 0;
    var totalPaidAmt = 0;
    var totalAmoPaid = 0;
    for (let i = 0; i < dateData.length; i++) {
      rowData.push(
        createData(
          dateData[i].payment_id,
          dateData[i].client_companyName,
          dateData[i].payment_paymentDate
            ? moment(dateData[i].payment_paymentDate).format("DD-MM-YYYY")
            : "",
          dateData[i].payment_invoiceNumber,
          dateData[i].payment_invoiceDate
            ? moment(dateData[i].payment_invoiceDate).format("DD-MM-YYYY")
            : "",
          dateData[i].payment_totalAmount?.toFixed(2),
          dateData[i].payment_paidAmount?.toFixed(2),
          dateData[i].payment_discount
            ? `${dateData[i].payment_discount} %`
            : "",
         ( dateData[i].payment_totalAmount - dateData[i].payment_paidAmount)?.toFixed(2)
        )
      );
      totalAmt += dateData[i].payment_totalAmount;
      totalDueAmt +=
        dateData[i].payment_totalAmount - dateData[i].payment_paidAmount;
      totalPaidAmt += dateData[i].payment_paidAmount;
      totalAmoPaid = totalAmt - totalDueAmt;
    }
    setTotalAmounts(totalAmt?.toFixed(2));
    setTotalDueAmounts(totalDueAmt?.toFixed(2));
    setTotalPaidAmounts(totalAmoPaid?.toFixed(2));
    setPaymentData(rowData);
  };

  const getClient = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClient(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const intializeClient = () => {
    var pushArray = [];

    for (let i = 0; i < client.length; i++) {
      pushArray.push({
        label: client[i].companyName,
      });
    }
    setPushArray(pushArray);
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  useEffect(() => {
    intializeClient();
  }, [client]);

  useEffect(() => {
    initiatInvoiceList();
  }, [dateData]);

  useEffect(() => {
    initiatInvoiceList();
  }, [paymentList]);

  useEffect(() => {
    getPaymentList();
  }, [rowsPerPage, page]);

  useEffect(() => {
    getTotalRows();
    getClient();
  }, []);

  useEffect(() => {
    if(searched==false){
      getPaymentList();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  return (
    <ListWrap>
      
      <div
        className="mb-2"
        style={{ display: "flex", float: "right", justifyContent: "flex-end" }}
      >
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
           className="Listinputcss"
          size="small"
          id="combo-box-demo"
          options={[
            { key: "payment.paymentDate", label: "Payment Date" },
            { key: "payment.invoiceDate", label: "Invoice Date" },
          ]}
          renderInput={(params) => (
            <TextField {...params} label="Select Date By" />
          )}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectDateBy(newValue.key);
            } else {
              setSelectDateBy("");
            }
            setSearched(false);
          }}
        />

        <div style={{ marginLeft: "10px", marginRight: "10px" , width:"120px"}}>
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
              className="Listinputcss"
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setFrom(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px",width:"120px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className="Listinputcss"
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setTo(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          className="Listinputcss"
          value={searchBy}
          id="combo-box-demo"
          options={[
            { key: "client.companyName", label: "Client" },
            { key: "payment.invoiceNumber", label: "Invoice Number" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
        <TextField
          id="outlined-basic"
          inputProps={{
            autoComplete: 'off',
          }}
          className="Listinputcss"
          label={"enter " + searchBy}
          size="small"
          value={searchKey}
          variant="outlined"
          onChange={(e) => {
            setSearchKey(e.target.value);
            setSearched(false);
          }}
          sx={{ marginLeft: "10px" }}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
        <ExcelDownload
          finalData={paymentData}
          notNeededColumn={notNeededColumn}
        />
      </div>
      <br />
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            id="datatable-keytable"
            sx={{ borderCollapse: "collapse" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentData?.length > 0 ? (
                paymentData.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                 <h6><b>Data Not Found</b></h6>
                </TableCell>
              </TableRow>
            )} 
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 30, 50]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
            colSpan={3}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            ActionsComponent={TablePaginationActions}
          />
          <ToastContainer />
          <br />
          {paymentData.length ? (
            <Grid
              container
              spacing={2}
              style={{ marginBottom: "10px" }}
              justifyContent="flex-end"
            >
              <Grid item xs={3} justify="flex-end">
                <TextField
                  id="outlined-basic"
                  label="Total Amount"
                  disabled
                  minvalue="100"
                  value={
                    totalAmounts != null && totalAmounts != 0
                      ? totalAmounts
                      : ""
                  }
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  sx={{ mt: 2 }}
                  id="outlined-basic"
                  label="Total Paid Amount"
                  disabled
                  minvalue="100"
                  value={
                    totalPaidAmounts != null && totalPaidAmounts != 0
                      ? totalPaidAmounts
                      : ""
                  }
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  sx={{ mt: 2 }}
                  id="outlined-basic"
                  label="Total Due Amount"
                  disabled
                  minvalue="100"
                  value={
                    totalDueAmounts != null && totalDueAmounts != 0
                      ? totalDueAmounts
                      : ""
                  }
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </TableContainer>
      
    </ListWrap>
  );
}
